<template>
  <div>
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="row">
            <b-col md="3" offset-md="2">
              <b-form-group :label="filter[0].label+':'">
                <select class="form-control" v-model.number="filter[0].value">
                  <option :value="null">Tout</option>
                  <option v-for="(s,i) in LotsStatus.filter(s => s.id != 5)" :key="i" :value="s.id">{{s.text}}</option>
                </select>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group :label="filter[1].label+':'">
                <b-form-datepicker v-model="filter[1].value"></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group :label="filter[2].label+':'">
                <b-form-input v-model.number="filter[2].value" required></b-form-input>
              </b-form-group>
            </b-col>
          </div>
          <div class="row">
            <div class="offset-md-5 col-md-4">
              <button type="button" class="btn btn-primary mr-1 mb-1 waves-effect waves-light" @click="datatableInit(true)">{{$store.back.getText('recherchez')}}</button>
              <button type="button" class="btn btn-danger mr-1 mb-1 waves-effect waves-light" @click="searchReset()">Reset</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-row>
      <b-col :md="lotSelected.id === null ? 12 : 7">
        <div class="card card-custom gutter-b mt-3">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">{{$store.back.getText('liste_lots_dispo')}}</h3>
            </div>
          </div>
          <div class="card-body">
            <b-row>
              <b-col md="1">
                <select class="form-control" v-model="datatable.limitPage" @change="datatableInit">
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </b-col>
            </b-row>
            <b-table @row-clicked="changeLot" responsive striped hover ref="table" :items="datatable.data" :fields="fields" @sort-changed="sortChanged" show-empty empty-text="Il n'y a aucun enregistrement à afficher">
              <template #cell(preneurs.etat)="data">
                <b-button class="btn-status" :variant="LotsStatus.find(s => s.id === data.value).color">{{LotsStatus.find(s => s.id === data.value).text}}</b-button>
              </template>
            </b-table>
            <b-pagination size="lg" align="center" v-model="datatable.currentPage" @change="changePage" :total-rows="datatable.totalRow" :per-page="datatable.limitPage" aria-controls="itemList"></b-pagination>
          </div>
        </div>
      </b-col>
      <b-col md="5" v-if="lotSelected.id != null">
        <div class="card mt-3">
          <div class="card-header">
            <div class="text-center col-12 mt-1">
              <div class="dropdown" style="z-index:1030">
                <b-button :variant="LotsStatus.find(s => s.id === lotSelected.etat).color" class="mr-1">{{LotsStatus.find(s => s.id === lotSelected.etat).text}}</b-button>
                <b-dropdown :text="$store.back.getText('documents')" variant="primary" class="mr-1">
                  <b-dropdown-item :href="'https://api.get-move.com/v1/inventaire/pdf/'+lotSelected.numero_cli" target="_blank">Inventaire</b-dropdown-item>
                  <b-dropdown-item v-if="(lotSelected.etat == 5 || lotSelected.etat == 6) && lotSelected.lv !== undefined" :href="lotSelected.lv" target="_blank">Lettre de voiture</b-dropdown-item>
                  <b-dropdown-item v-if="(lotSelected.etat == 5 || lotSelected.etat == 6) && lotSelected.bc !== undefined" :href="lotSelected.bc" target="_blank">Bon de commande</b-dropdown-item>
                  <b-dropdown-item v-if="(lotSelected.etat == 5 || lotSelected.etat == 6) && lotSelected.om !== undefined" :href="lotSelected.om" target="_blank">Ordre de mission</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
          <Map :waypoints="waypoints" />
        </div>
        <div class="card mt-2">
          <div class="card-content">
            <div class="card-body">
              <h4 class="card-title">{{$store.back.getText('info_dem')}}</h4>
              <div class="form " id="infoDem">
                <div class="form-body">
                  <div class="row">
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('date_charge')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.date_depart" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('cubage')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.cubage" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('type_presta')}} </label>
                        <select type="text" class="form-control" v-model="lotSelected.categorie" disabled>
                          <option value="1">{{$store.back.getText('categorie')}} {{lotSelected.categorie}}</option>
                          <option value="2">{{$store.back.getText('categorie')}} {{lotSelected.categorie}}</option>
                          <option value="3">{{$store.back.getText('categorie')}} {{lotSelected.categorie}}</option>
                          <option value="4">{{$store.back.getText('categorie')}} {{lotSelected.categorie}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('type_transport')}}</label>
                        <input v-if="lotSelected.distance > 200" type="text" class="form-control"  disabled>
                        <input v-else type="text" class="form-control" :value="$store.back.getText('urbain')" disabled>
                      </div>
                    </div><div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('obj_lourds')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.objet_lourd" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('prix_ttc')}}</label>
                        <input type="text" class="form-control" :value="getPrice(lotSelected,true,false)" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('type_dem')}}</label>
                        <select type="text" class="form-control" v-model="lotSelected.type_lot" disabled>
                          <option value="1">{{$store.back.getText('dem_parti')}}</option>
                          <option value="2">{{$store.back.getText('dem_ste')}}</option>
                          <option value="3">{{$store.back.getText('dem_inter')}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('type_livraison')}}</label>
                        <select type="text" class="form-control"  v-model="lotSelected.type_livraison" disabled>
                          <option value="1">{{$store.back.getText('livraison_date_fix')}}</option>
                          <option value="2">{{$store.back.getText('livrason_jour')}}</option>
                          <option value="3">{{$store.back.getText('livraison_suivant')}}</option>
                          <option value="4">{{$store.back.getText('livraison_groupage')}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('val_assur')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.montant_assurer" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('materiel_prev')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.materiel_aprevoir" disabled>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card mt-2">
          <div class="card-content">
            <div class="card-body">
              <h4 class="card-title">{{$store.back.getText('info_depart')}}</h4>
              <form class="form " id="InfoDepart">
                <div class="form-body">
                  <div class="row">
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('date')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.date_depart" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('cp')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.cp_depart" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('etage')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.etage_depart" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('monte_meuble')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.monte_meuble_depart" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('portage')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.portage_depart" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('ast')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.autorisationstationnement_depart" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('tranche_date')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.periode_flexible_depart" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('ville')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.ville_depart" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('ascenseur')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.ascenceur_util_depart" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('passage_fenetre')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.passage_fen_depart" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('transbordement')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.transbordement_depart" disabled>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('obser')}}</label>
                        <textarea class="form-control" v-model="lotSelected.commentaire_depart" disabled rows="3"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="card mt-2">
          <div class="card-content">
            <div class="card-body">
              <h4 class="card-title">{{$store.back.getText('info_arrivee')}}</h4>
              <form class="form " id="InfoArr">
                <div class="form-body">
                  <div class="row">
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('date')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.date_arrivee" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('cp')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.cp_arrivee" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('etage')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.etage_arrivee" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('mt')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.monte_meub_arrivee" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('portage')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.portage_arrivee" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('ast')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.autorisationstationnement_arrivee" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('tranche_date')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.periode_flexible_arrivee" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('ville')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.ville_arrivee" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('ascenseur')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.ascenceur_util_arrivee" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('passage_fenetre')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.passage_fen_arrivee" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('transbordement')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.transbordement_arrivee" disabled>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('obser')}}</label>
                        <textarea class="form-control" v-model="lotSelected.commentaire_arrivee" disabled rows="3"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <button class="btn btn-danger btn-block" @click="lotSelected.id = null"><i class="fa fa-arrow-left"></i></button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Map from '@/components/Map'

export default {
  data(){
    return {
      volume_filter:true,
      distance_filter:true,
      items: [],
      filter:[
        {
            "key":"preneurs.etat",
            "label":"Etat",
            "type":"Integer",
            "value":null
        },
        {
            "key":"lot.date_depart",
            "label":"Date depart",
            "type":"String",
            "value":null
        },
        {
            "key":"id",
            "label":"Ref. Lot",
            "type":"Integer",
            "value":null
        }
      ],
      fields:[
        {
          key:"id",
          label: "Ref",
          sortable:true,
          searchable:false

        },
        {
          key:"lot.date_depart",
          label:"Date depart",
          sortable:true,
          searchable:false
        },
        {
          key:"lot.periode_flexible_depart",
          label:"Date flexible",
          sortable:true,
          searchable:false
        },
        {
          key:"lot.cp_depart",
          label:this.$store.back.getText('cp_depart'),
          sortable:true,
          searchable:false
        },
        {
          key:"lot.cp_arrivee",
          label:this.$store.back.getText('cp_livraison'),
          sortable:true,
          searchable:false
        },
        {
          key:"lot.distance",
          label:this.$store.back.getText('distance'),
          sortable:true,
          searchable:false
        },
        {
          key:"lot.cubage",
          label:this.$store.back.getText('volume'),
          sortable:true,
          searchable:false
        },
        {
          key:"lot.prix_dailycall_ht",
          label:'Prix TTC',
          sortable:true,
          searchable:false
        },
        {
          key:"lot.prix_dailycall_ht",
          label:'Prix TTC',
          sortable:true,
          searchable:false
        },
        {
          key:"preneurs.etat",
          label:'Etat',
          sortable:true,
          searchable:false
        }
      ],
      datatable:{
        currentPage: 1,
        data:[],
        limitPage: 20,
        nextPage: 0,
        previousPage: 0
      },
      sort:{
        key: 'date_depart',
        value: 'DESC'
      },
      lotSelected:{
        id:null,
        date_depart:"",
        cubage:"",
        categorie:"",
        objet_lourd:"",
        prix_ttc:"",
        type_lot:"",
        type_livraison:"",
        montant_assurer:"",
        materiel_aprevoir:"",
        cp_depart:"",
        etage_depart:"",
        monte_meuble_depart:"",
        portage_depart:"",
        autorisationstationnement_depart:"",
        commentaire_depart:"",
        periode_flexible_depart:"",
        ville_depart:"",
        ascenceur_util_depart:"",
        passage_fen_depart:"",
        transbordement_depart:"",
        date_arrivee:"",
        cp_arrivee:"",
        monte_meub_arrivee:"",
        portage_arrivee:"",
        autorisationstationnement_arrivee:"",
        periode_flexible_arrivee:"",
        ville_arrivee:"",
        ascenceur_util_arrivee:"",
        passage_fen_arrivee:"",
        transbordement_arrivee:"",
        etat:"",
        nom_cli:"",
        prenom_cli:"",
        tel_cli:"",
        mob_cli:"",
        email_cli:"",
        adresse_depart:"",
        adresse_arrivee:"",
        position:true
      },
      map:null,
      preneur: {
        date:null,
        prix:null,
      },
      groupes:[],
      LotsStatus:[
        {
          "id": 2,
          "color": "primary",
          "text": "En attente"
        },
        {
          "id": 3,
          "color": "danger",
          "text": "Annuler"
        },
        {
          "id": 5,
          "color": "success",
          "text": "Vendu"
        },
        {
          "id": 6,
          "color": "success",
          "text": "Vendu"
        }
      ]
    };
  },
  components: {
    Map
  },
  watch: {
  },
  beforeMount() {
    this.$store.back.ajax('/market/groupes', null, (res) => {
      if(res.status === true){
        this.groupes = res.data.liste;
      }
    });
  },
  methods: {
    datatableInit: function(){
      var filter = JSON.stringify(this.filter);
      filter = JSON.parse(filter);
      if(filter[1].value != null){
        filter[1].value = filter[1].value.split('-').reverse().join('/');
      }
      var params = {
        filter: filter,
        sort: this.sort,
        limit: this.datatable.limitPage,
        page: this.datatable.currentPage
      }
      this.$store.back.ajax('/market/lots/postule', params, (res) => {
        this.datatable = res;
        this.datatable.meta.docs = true
      });
    },
    sortChanged(e){
      this.sort = {
        key: e.sortBy,
        value: e.sortDesc === true ? "DESC" : "ASC"
      };
      this.datatable.data = [];
      this.datatableInit();
    },
    searchReset(){
      this.filter[0].value = null;
      this.filter[1].value = null;
      this.filter[2].value = null;
      this.datatableInit();
    },
    changePage(e){
      this.datatable.currentPage = e;
      this.datatableInit();
    },
    setVolume(e){
      this.filter[0].value = parseInt(e.values[0])
      this.filter[1].value = parseInt(e.values[1])
    },
    setDistance(e){
      this.filter[2].value = parseInt(e.values[0])
      this.filter[3].value = parseInt(e.values[1]);
    },
    changeLot(row){
      this.lotSelected.id = null;
      setTimeout(() => {
        this.$store.back.ajax(`/market/lots/${row.id}`, null, (res) => {
          if(res.status === true){
            res.data.lot.id = row.id;
            var l = JSON.stringify(res.data.lot);
            l = JSON.parse(l);
            this.lotSelected = l;
          }
        })
      },250);
    },
    getPrice(row,tva = false,formule = false){
      var franchise = 0;
      var franchisemax = 0;
      if(row.lot.suppfranch == "on"){
        var assurance = ( parseFloat(row.lot.montant_assurer)*0.6) /100;
        franchisemax = (parseInt(row.lot.prix_dailycall_ht)*8)/100;
        if(franchisemax < assurance ){
          franchise = franchisemax;
        }else{
          franchise = assurance;
        }
      }
      if(formule === true){
        return franchise;
      }
      var supplement = row.lot.supplement === "" || row.lot.supplement === null ? 0 : parseFloat(row.lot.supplement);
      var priceHT = parseFloat(row.lot.prix_dailycall_ht) - parseInt(franchise) + supplement;
      if(tva === false){
        return priceHT.toFixed(2)
      }else{
        if(row.lot.exo_tva == "on"){
          return priceHT.toFixed(2);
        }else{
          var ratioTVA = (100+row.secteur.tva)/100;
          return (priceHT*ratioTVA).toFixed(2);
        }
      }
    }
  },
  computed: {
    waypoints(){
      if(this.lotSelected.lon_a === null || this.lotSelected.lon_d === null){
        this.$bvToast.toast(this.$store.back.getText('merci_renseigner'), {
          title: 'Erreur !',
          variant: 'danger',
          solid: true
        });
        return false
      }
      return [
        { lat: this.lotSelected.lat_d, lng: this.lotSelected.lon_d },
        { lat: this.lotSelected.lat_a, lng: this.lotSelected.lon_a }
      ];
    },
  },
  mounted() {
    this.datatableInit();
  }
};
</script>
<style media="screen">
  .btn-status{
    font-size: 1.025rem;
    line-height: 1.35;
    border-radius: 0.42rem;
    padding: 5px 3px;
  }
</style>
